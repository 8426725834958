import React from 'react';
import {SpotifyWrapper} from './styles';
import {Block} from '../../interface';
import {hexToRgb} from '../..';

interface Props {
  theme: string;
  block: Block;
  isEditing: boolean;
  mobileOverview: boolean;
}

const SpotifyBlock: React.FC<Props> = ({block, isEditing}) => {
  // function convertToEmbedLink(spotifyUrl) {
  //   // Sprawdzenie, czy URL już zawiera "/embed"
  //   if (spotifyUrl.includes('/embed')) {
  //     return spotifyUrl;
  //   }

  //   // Rozdzielenie URL na części przed i po "/album/", "/track/", itd.
  //   const parts = spotifyUrl.split(
  //     /(\/album\/|\/track\/|\/playlist\/|\/artist\/)/,
  //   );

  //   if (parts.length < 3) {
  //     // Jeśli URL jest nieprawidłowy lub nie zawiera rozpoznawanego formatu, zwróć oryginalny URL
  //     return spotifyUrl;
  //   }

  //   // Dodanie "/embed" do URL
  //   return parts[0] + '/embed' + parts[1] + parts[2];
  // }

  function convertToEmbedLink(spotifyUrl: string) {
    // Sprawdzenie, czy URL jest poprawnym adresem Spotify (album, track, playlist, artist)
    const spotifyRegex =
      /^(https?:\/\/)?(www\.)?(open\.spotify\.com\/(album|track|playlist|artist)\/[a-zA-Z0-9]+)$/;

    if (!spotifyRegex.test(spotifyUrl)) {
      console.error('Nieprawidłowy URL Spotify');
      return null; // Zwróć null, jeśli URL jest nieprawidłowy
    }

    // Sprawdzenie, czy URL już zawiera "/embed"
    if (spotifyUrl.includes('/embed')) {
      return spotifyUrl;
    }

    // Rozdzielenie URL na części przed i po "/album/", "/track/", itd.
    const parts = spotifyUrl.split(
      /(\/album\/|\/track\/|\/playlist\/|\/artist\/)/,
    );

    if (parts.length < 3) {
      // Jeśli URL jest nieprawidłowy lub nie zawiera rozpoznawanego formatu, zwróć oryginalny URL
      return spotifyUrl;
    }

    // Dodanie "/embed" do URL
    return parts[0] + '/embed' + parts[1] + parts[2];
  }

  return (
    <SpotifyWrapper
      bgColor={hexToRgb(block.BackgroundColorHex)}
      backgroundAlpha={block.BlockOpacityIn0To100Ratio}
    >
      {' '}
      {block.SpotifyUrl === '' ? null : (
        <iframe
          style={{borderRadius: '12px'}}
          src={block.SpotifyUrl}
          width="100%"
          height="352"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      )}
    </SpotifyWrapper>
  );
};

export default React.memo(SpotifyBlock);
