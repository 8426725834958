import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useNavigate} from 'react-router-dom';

import {RecommendedArticle} from '../index';

import {
  ArticlesWrapper,
  MainWrapper,
  SingleArticleWrapper,
  SliderWrapper,
  MobileArticlesWrapper,
  StyledSectionHeader,
  MobileSingleArticleWrapper,
} from './styles';

interface Props {
  RecommendedArticles: RecommendedArticle[];
}

export const Articles: React.FC<Props> = ({RecommendedArticles}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite:
      RecommendedArticles && RecommendedArticles.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 10000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const splitArrayIntoChunks = (array, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <MainWrapper>
      <StyledSectionHeader>
        <h1>Inspirujące artykuły specjalistyczne</h1>
        <h1>Inspirujące artykuły</h1>
        <a href="/czytelnia">
          Zobacz więcej
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.438"
            height="11"
            viewBox="0 0 6.438 11"
          >
            <g
              id="Arrow_Top_Bar_Accent"
              data-name="Arrow – Top Bar / Accent"
              transform="translate(0)"
              opacity="0.92"
            >
              <path
                id="Arrow"
                d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                transform="translate(0 0)"
                fill="#0091ff"
              />
            </g>
          </svg>
        </a>
      </StyledSectionHeader>
      <ArticlesWrapper>
        {RecommendedArticles?.map((article, index) => {
          const {Category, Description, Id, PicturePath, Title, UrlPath} =
            article;

          if (index > 2) return;
          return (
            <SingleArticleWrapper
              key={Id}
              onClick={() => navigate(`/czytelnia/${UrlPath}`)}
            >
              <img src={PicturePath} alt="image" />
              <h2>{Category}</h2>
              <h1>{Title}</h1>
              <p>{Description}</p>
            </SingleArticleWrapper>
          );
        })}
      </ArticlesWrapper>
      <SliderWrapper>
        <Slider {...settings}>
          {RecommendedArticles &&
            splitArrayIntoChunks(RecommendedArticles, 1).map((chunk, index) => {
              return (
                <MobileArticlesWrapper key={index}>
                  {chunk?.map((article, index) => {
                    const {
                      Category,
                      Description,
                      Id,
                      PicturePath,
                      Title,
                      UrlPath,
                    } = article;

                    return (
                      <MobileSingleArticleWrapper
                        key={Id}
                        onClick={() => navigate(`/czytelnia/${UrlPath}`)}
                      >
                        <img src={PicturePath} alt="image" />
                        <h2>{Category}</h2>
                        <h1>{Title}</h1>
                        <p>{Description}</p>
                      </MobileSingleArticleWrapper>
                    );
                  })}
                </MobileArticlesWrapper>
              );
            })}
        </Slider>
      </SliderWrapper>
    </MainWrapper>
  );
};
