import React, {useContext} from 'react';
import RGL, {WidthProvider} from 'react-grid-layout';
import 'pages/MyStudio/BusinessCard/style.css';

import {UserThemeContext} from 'contexts/UserThemeContext';

import {Props} from './interface';

import styled, {css} from 'styled-components';
import {Block} from '../interface';
import {ResolutionContext} from 'contexts/ResolutionContext';
import {hexToRgb, renderBlockContent} from '../index';
import {
  CardHeaderStatusWrapperRight,
  CardHeaderSvgWrapperLeft,
  CardSvg,
  NoPlanBtnWrapper,
  NoPlanSvgWrapper,
  NoPlanText,
  NoPlanWrapper,
  StatusWrapper,
} from '../styles';

const Main = styled.div<{overview?: boolean; isBasic: boolean}>`
  /* width: 100%; */
  max-width: 425px;
  width: 420px;
  position: relative;
  pointer-events: all;
  /* min-height: calc(100vh - 105px); */
  border-radius: 24px;
  height: 78vh;
  top: ${({overview}) => (overview ? '-55px' : '0')};
  margin: ${({overview}) => (overview ? '0 auto ' : ' 0 auto')};
  @media (max-width: 1024px) {
    width: 420px;
    /* min-height: calc(100vh - 105px); */
    height: 100%;
    top: 0;
    /* margin-top: 55px; */
  }
  @media (min-width: 425px) {
    overflow: hidden;
    width: 420px;
    border-radius: ${({isBasic}) => (isBasic ? 'none' : ' none')};
  }
  @media (max-width: 420px) {
    overflow: hidden;
    width: 100%;
  }
  @media (max-width: 340px) {
    width: 100%;
  }
  border: ${({isBasic}) => (isBasic ? 'none ' : '1px solid #c7c9cf')};
  background: ${({theme, isBasic}) =>
    isBasic ? theme.colors.bgWhite : 'transparent'};
`;

export const NewMainHeader = styled.div<{isOverview: boolean}>`
  width: 100%;
  min-height: 55px;
  /* height: 62px; */
  z-index: 2;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  /* border-bottom: ${({theme}) => `1px solid ${theme.colors.bodyBg}`}; */
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  /* background: #f1f1f2; */
  background: ${({theme}) => theme.colors.bodyBg};
  display: ${({isOverview}) => (isOverview ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.15s;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 21px;
    font-size: 18px;
    letter-spacing: -0.15px;
  }
  @media (max-width: 1024px) {
    display: none;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    & > P {
      font-size: 13px;
    }
  }
`;

const MainPanelWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const AltPanelTextWrapper = styled.div<{
  fontColor: string;
  blockWidth: number;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  & > h1 {
    font-size: 32px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    line-height: 36px;
    font-weight: 400;
  }

  & > h2 {
    font-size: 16px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    line-height: 24px;
    font-weight: 400;
  }

  & > h3 {
    font-size: 16px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    line-height: 24px;
    padding-left: 21px;
    font-weight: 400;
  }

  & > p {
    font-size: 11px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    opacity: 0.76;
    text-align: justify;
    padding-left: 21px;
    margin-top: 20px;
  }
`;

const MainPanelContentWrapper = styled.div<{overview?: boolean}>`
  width: 100%;
  /* min-height: calc(100vh); */
  height: 100vh;

  border-top: none;
  padding-left: ${({overview}) => (overview ? '0px' : '1px')};
  position: relative;
  border-bottom-left-radius: ${({overview}) => (overview ? 'none' : '15px')};
  border-bottom-right-radius: ${({overview}) => (overview ? 'none' : '15px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: ${({overview}) => (overview ? '0px' : '2px')};
    height: 10px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 1.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 1.5px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 57px;
  }
`;

const AltPanelContent = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
  blockWidth: number;
  overview?: boolean;
}>`
  width: ${({overview, blockWidth}) =>
    overview && blockWidth === 1 ? '100.28%' : '100%'};
  height: 100%;
  border-radius: 24px;
  /* background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`}; */
  display: flex;
  justify-content: space-around;
`;

const PanelContainerMobile = styled.div<{
  blockWidth: number;
  blockHeight: number;
  isEditing: boolean;
  overview?: boolean;
}>`
  /* height: 100%; */
  height: auto;
  width: 100%;
  transition: width 0.15s, height 0.15s;
  grid-row: ${({blockHeight}) => {
    if (blockHeight >= 1 && blockHeight <= 9) {
      return `span ${blockHeight}`;
    } else {
      return 'span 9';
    }
  }};
  grid-column: ${({blockWidth}) => {
    if (blockWidth >= 1 && blockWidth <= 9) {
      return `span ${blockWidth}`;
    } else {
      return 'span 9';
    }
  }};
  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    /* height:100%; */
  }
`;

const GridStructur = styled.div<{screenWidth: number}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  padding-left: 1px;
  padding-right: 1px;
  grid-template-columns: ${({screenWidth}) =>
    screenWidth > 420 ? `repeat(6,${56}px)` : `repeat(6,${screenWidth / 6}px)`};
  grid-auto-rows: ${({screenWidth}) =>
    screenWidth > 420 ? `56px` : `${screenWidth / 6}px`};
`;
const GridOneItem = styled.div`
  display: none;
  z-index: -1;
  border: 1px dashed #707070;
  opacity: 0.3;
  border-right: none;
  border-left: none;
  border-top: none;
  //@media (min-width: 425px) {
  //  height: 142px;
  //}
`;

const TextForMobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  & > p {
    font-size: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

const MobileOverviewComponent: React.FC<Props> = ({
  roles,
  overview,
  setOverview,
  Blocks,
  isEditing,
  setProducer,
  newLayout,
  mobileOverview,
  activePlan,
  draftMatchPublish,
  draftStatus,
  publishedCardOutOfDate,
  mobileGoToProducer,
}) => {
  // const toggleOverview = () => setOverview(!overview);
  const ReactGridLayout = WidthProvider(RGL);
  const [theme, setTheme] = useContext(UserThemeContext);

  const {windowWidth} = useContext(ResolutionContext);

  // if (mobileGoToProducer) {
  //   window.location.href = '/producer';
  // }

  if (mobileGoToProducer) {
    return (
      <TextForMobileWrapper>
        <p>Edycja wizytówki dostępna tylko w wersji desktop.</p>
      </TextForMobileWrapper>
    );
  }

  return (
    <Main
      overview={overview}
      isBasic={activePlan === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94'}
    >
      <MainPanelWrapper>
        <NewMainHeader isOverview={overview}>
          <CardHeaderSvgWrapperLeft>
            <CardSvg
              isActive={!mobileOverview}
              onClick={() => setOverview(false)}
              className="WEBICON"
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="32"
              viewBox="0 0 34 32"
            >
              <g
                id="Group_7970"
                data-name="Group 7970"
                transform="translate(-860 -99)"
              >
                <g
                  id="Rectangle_4245"
                  data-name="Rectangle 4245"
                  transform="translate(860 99)"
                  fill="none"
                  stroke="#0091ff"
                  strokeWidth="1"
                >
                  <rect width="34" height="32" rx="9" stroke="none" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="33"
                    height="31"
                    rx="8.5"
                    fill="none"
                  />
                </g>
                <g
                  id="Group_7967"
                  data-name="Group 7967"
                  transform="translate(869 108.5)"
                >
                  <path
                    id="Path_4204"
                    data-name="Path 4204"
                    d="M7.264,8H5.632v9.791H18.686V8Zm9.791,1.632v6.527H7.264V9.632Zm3.264,9.791H4v1.632H20.318Z"
                    transform="translate(-4 -8)"
                  />
                </g>
              </g>
            </CardSvg>
            <CardSvg
              isActive={mobileOverview}
              onClick={() => {
                setOverview(true);
              }}
              className="MOBILEICON"
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="32"
              viewBox="0 0 34 32"
            >
              <g
                id="Group_7969"
                data-name="Group 7969"
                transform="translate(-904 -99)"
              >
                <g
                  id="Rectangle_1948"
                  data-name="Rectangle 1948"
                  transform="translate(904 99)"
                  fill="none"
                  stroke="rgba(23,22,29,0.21)"
                  strokeWidth="1"
                >
                  <rect width="34" height="32" rx="9" stroke="none" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="33"
                    height="31"
                    rx="8.5"
                    fill="none"
                  />
                </g>
                <g
                  id="Group_7968"
                  data-name="Group 7968"
                  transform="translate(916 109)"
                  // opacity="0.5"
                >
                  <path
                    id="Path_4206"
                    data-name="Path 4206"
                    d="M9.326,4H8V17H18.606V4Zm7.955,1.3V15.7H9.326V5.3Zm-3.315,7.8H12.64v1.3h1.326Z"
                    transform="translate(-8 -4)"
                    fill="#17161d"
                  />
                </g>
              </g>
            </CardSvg>
          </CardHeaderSvgWrapperLeft>
          <p>Wizytówka</p>
        </NewMainHeader>
        <MainPanelContentWrapper overview={overview}>
          {/*<CloseButton*/}
          {/*  onClick={() => toggleOverview()}*/}
          {/*  // style={{position: 'absolute', left: '-111px'}}*/}
          {/*>*/}
          {/*  Zamknij mobilny*/}
          {/*</CloseButton>*/}
          {activePlan === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94' ? (
            <NoPlanWrapper>
              <NoPlanSvgWrapper>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.913"
                  height="31.928"
                  viewBox="0 0 28.913 31.928"
                >
                  <path
                    id="Icon_simple-webpack"
                    data-name="Icon simple-webpack"
                    d="M31.524,27.18l-13,7.32V28.8l8.1-4.435,4.9,2.816Zm.889-.8V11.071L27.656,13.8v9.839Zm-28.024.8,13,7.32V28.8l-8.1-4.435-4.9,2.816Zm-.889-.8V11.071L8.256,13.8v9.839L3.5,26.379Zm.556-16.3,13.33-7.508V8.084L8.846,12.76l-.065.037L4.056,10.08Zm27.8,0L18.527,2.572V8.084l8.54,4.677.065.037,4.725-2.717ZM17.386,27.5,9.4,23.129V14.463l7.989,4.593V27.5Zm1.141,0,7.989-4.373V14.463l-7.989,4.593V27.5ZM9.938,13.46l8.02-4.392,8.02,4.392-8.02,4.612Z"
                    transform="translate(-3.5 -2.572)"
                    fill="#0091ff"
                  />
                </svg>
              </NoPlanSvgWrapper>
              <NoPlanText>
                Zmień plan działania aby móc korzystać ze Strony Twórcy.
              </NoPlanText>
              <NoPlanText>Więcej info w zakładce PRO.</NoPlanText>
              <NoPlanBtnWrapper onClick={() => (window.location.href = 'pro')}>
                <p>Zobacz plany działania</p>
              </NoPlanBtnWrapper>
            </NoPlanWrapper>
          ) : (
            <>
              {(windowWidth <= 1024
                ? [...Blocks].reverse()
                : [...Blocks].reverse()
              ).map((block, index) => {
                const {
                  BackgroundColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id,
                  Type,
                  MobileArea,
                } = block;
                const {StartX, StartY, Width, Height} = MobileArea;
                const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
                const FontColorRgb = hexToRgb(FontColorHex);

                return (
                  <PanelContainerMobile
                    key={Id}
                    isEditing={isEditing}
                    blockWidth={Width}
                    blockHeight={Height}
                    overview={overview}
                  >
                    <AltPanelContent
                      backgroundAlpha={BlockOpacityIn0To100Ratio}
                      backgroundColor={BackgroundColorRgb}
                      blockWidth={Width}
                      overview={overview}
                    >
                      <AltPanelTextWrapper
                        fontColor={FontColorRgb}
                        blockWidth={Width}
                      >
                        {renderBlockContent(
                          roles,
                          block,
                          theme,
                          mobileOverview,
                          false,
                        )}
                      </AltPanelTextWrapper>
                      {/* <AltPanelImage
                        blockWidth={Width}
                        blockHeight={Height}
                      ></AltPanelImage> */}
                    </AltPanelContent>
                  </PanelContainerMobile>
                );
              })}
              {/* </ReactGridLayout> */}
            </>
          )}
          {/* </BlocksWrapper> */}
        </MainPanelContentWrapper>
      </MainPanelWrapper>
    </Main>
  );
};

export default MobileOverviewComponent;
