import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const LoginWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 15px;
  & > h2 {
    font-size: 28px;
    line-height: 44px;
  }
`;

export const PrimaryButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 120px;
  text-align: center;
  height: 32px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({background}) => (!background ? '#3A87F0' : background)};
  border-radius: 15px;
  font-size: 15px;
  color: ${({color}) => (!color ? '#ffffff' : color)};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  //justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;
  display: flex;
  justify-content: center;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 16px;
    letter-spacing: 0.07px;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 7px;

    &:first-of-type {
      margin-bottom: 21px;
    }
  }
`;

export const InputView = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  position: relative;
  width: 300px;
  /* margin-top: 13px; */
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  & > input {
    outline: none;
    background: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? theme.colors.statusRed
          : 'transparent'
        : 'transparent'};
    border: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({isEditing, theme}) =>
      isEditing ? theme.colors.fontBlackOp50 : theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    text-align: right;
    //font-size: 11px;
    font-size: 12px;
    padding-right: 8.5px;
    padding-left: 110px;
    &:-webkit-autofill {
      -webkit-text-fill-color: ${({theme}) =>
        theme.colors.fontBlackOp50} !important;
    }
  }
  & > input[type='date']::-webkit-calendar-picker-indicator {
    display: ${({isEditing, notEdit}) =>
      isEditing && !notEdit ? `block` : `none`};
  }

  & > input:-webkit-autofill {
    border: 1px solid rgb(225, 225, 225);
    /* transition: background-color 0s ease-in-out 0s; */
  }

  & > input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: ${({theme}) => theme.colors.fontBlackOp100} !important;
    -webkit-text-fill-color: ${({theme}) =>
      theme.colors.fontBlackOp100} !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
  }

  & > input:focus {
    background: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
    border: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? ` 1px solid ${theme.colors.blueTheme}`
        : notEdit && isEditing
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? `solid 1px ${theme.colors.blueTheme} `
        : notEdit
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
  }
  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing, theme}) =>
      !isEditing
        ? `${theme.colors.fontBlackOp50}`
        : `${theme.colors.fontBlackOp100}`};
    //font-size: 11px;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 8.5px;
    top: 0px;
    cursor: default;
  }

  @media (max-width: 1024px) {
    & > input[type='date'] {
      text-align: right !important;
    }
    width: 358px;
    height: 28px;
    background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.inputMobileBg : 'transparent'};
    border-radius: ${({isEditing}) => (isEditing ? '5px' : '0px')};
    /* border: ${({isEditing, notEdit}) =>
      isEditing && !notEdit
        ? ` 1px solid rgba(112,112,112,0.3)`
        : notEdit && isEditing
        ? 'solid 1px rgba(112,112,112,0.3)'
        : 'solid 1px rgba(112,112,112,0.3)'}; */
    /* border: 1px solid transparent; */
    border: ${({isEditing, theme}) =>
      isEditing ? `1px solid ${theme.colors.inputMobileBorderColor}` : 'none'};
    border-bottom: ${({theme}) =>
      `1px solid ${theme.colors.inputMobileBorderColor}`};
    /* box-shadow: ${({isEditing, theme}) =>
      isEditing ? 'none' : theme.colors.inputBoxShadowColor}; */
    &:focus-within {
      background: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
      border: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? ` 1px solid ${theme.colors.blueTheme}`
          : notEdit && isEditing
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : 'none'};
      border-bottom: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? `solid 1px ${theme.colors.fontBlue}`
          : notEdit
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : `solid 1px ${theme.colors.fontBlackOp15}`};
    }
    & > input {
      width: 100%;
      height: 100%;
      font-size: 13px;
      line-height: 22px;
      font-weight: 500;
      letter-spacing: 0.625px;
      border: none;
      //opacity: ${({isEditing}) => (isEditing ? '1' : '0.3')};
      color: ${({isEditing, theme}) =>
        isEditing ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp100};
      padding-left: 0;
      padding-right: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
      border-radius: 5px;
    }

    & > input:focus {
      background: transparent;
      border: none;
      border-bottom: none;
    }
    & > p {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.625px;
      left: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
    }
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

const Protectedlogin = ({setAccessGranted}) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (password === 'TestoweMugoStudio') {
      setAccessGranted(true);
      navigate('/');
    } else {
      alert('Nieprawidłowe hasło!');
    }
  };

  return (
    <LoginWrapper>
      <h2>Podaj hasło dostępu:</h2>
      <InputView isEditing={true}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </InputView>

      <PrimaryButton onClick={handleLogin}>Zatwierdź</PrimaryButton>
    </LoginWrapper>
  );
};

export default Protectedlogin;
